<template>
	<div class="bigDiv">
		<div>
			<!-- 面包屑导航 -->
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item>门店管理</el-breadcrumb-item>
			  <el-breadcrumb-item>门店列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top:20px">
			<el-card>
				<el-row :gutter="10">
					<el-col :span="8">
						<el-input placeholder="请输入门店ID查询" v-model="queryInfo.query" class="input-with-select" clearable @clear="seachVal">
						<el-button slot="append" icon="el-icon-search" @click="ListArr"></el-button>
						</el-input>
					</el-col>
				</el-row>
				<el-table
				      :data="ArrList"
				      style="margin-top: 20px;"
					  border>
					  <el-table-column
						label="序号"
					   align="center"
						type="index"
						width="50">
					  </el-table-column>
				      <el-table-column
				        prop="SID"
				        label="门店ID"
				        width="100">
				      </el-table-column>
				      <el-table-column
				        prop="SMOBILE"
				        label="手机号"
				        width="150">
				      </el-table-column>
					  <el-table-column
					    prop="CDATE"
					    label="创建时间"
					    width="180">
					  </el-table-column>
					  <el-table-column
					    prop="TERMDATE"
					    label="授权日期"
					    width="180">
					  </el-table-column>
					  <el-table-column
					    prop="DLID"
					    label="代理商ID"
					    width="180">
					  </el-table-column>
					  <el-table-column
					    prop="orgId"
					    label="机构号"
					    width="180">
					  </el-table-column>
					  <el-table-column
					    prop="orgMercode"
					    label="商户号"
					    width="180">
					  </el-table-column>
					  <el-table-column
					    prop="orgTermno"
					    label="终端号"
					    width="150">
					  </el-table-column>
				      <!-- <el-table-column
				        prop="ISCHECKED"
				        label="状态"
				        align="center"
				        width="250">
				        <template slot-scope="scope">
				          <el-tag
				        	:type="scope.row.ISCHECKED === 0 ? 'success' : 'danger'"
				        	disable-transitions>{{scope.row.ISCHECKED==0?'正常':'禁用'}}</el-tag>
				        </template>
				      </el-table-column> -->
					  <el-table-column label="操作" v-if="GRADE == '99'">
						<template slot-scope="scope">
						  <el-button
							size="mini"
							@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
						</template>
					  </el-table-column>
				    </el-table>
					<!-- 分页功能 -->
					<div style="margin-top: 20px;">
						<el-pagination
						  @size-change="SizeChange"
						  @current-change="CurrentChange"
						  :current-page="queryInfo.pagenum"
						  :page-sizes="[10, 30, 50]"
						  :page-size="queryInfo.pagesize"
						  layout="total, sizes, prev, pager, next, jumper"
						  :total="total">
						</el-pagination>
					</div>
			</el-card>
		</div>
		<!-- 左侧抽屉 -->
		<el-drawer
		  title='门店设置'
		  :visible.sync="drawer"
		  :before-close="handleClose"
		  >
		  <div class="drawer">
			  <div>
				  <el-form label-position="left" :model="addListForm" :rules="addListFormRul" ref="addListForm" label-width="100px" class="demo-ruleForm">
				      <el-form-item label="门店ID" prop="SID">
				        <el-input v-model="addListForm.SID" disabled></el-input>
				      </el-form-item>
				  	<el-form-item label="手机号" prop="SMOBILE">
				  	  <el-input v-model="addListForm.SMOBILE" disabled></el-input>
				  	</el-form-item>
				  	<el-form-item label="创建时间" prop="CDATE">
				  	  <el-input v-model="addListForm.CDATE" disabled></el-input>
				  	</el-form-item>
					<el-form-item label="登陆密码" prop="PASSWORD">
					  <el-input type="pass" v-model="addListForm.PASSWORD"></el-input>
					</el-form-item>
					<el-form-item label="代理商ID" prop="DLID">
					  <el-input v-model="addListForm.DLID"></el-input>
					</el-form-item>
					<el-form-item label="机构号" prop="orgId">
					  <el-input v-model="addListForm.orgId"></el-input>
					</el-form-item>
					<el-form-item label="商户号" prop="orgMercode">
					  <el-input v-model="addListForm.orgMercode"></el-input>
					</el-form-item>
					<el-form-item label="终端号" prop="orgTermno">
					  <el-input v-model="addListForm.orgTermno"></el-input>
					</el-form-item>
					<el-form-item label="授权日期" prop="TERMDATE">
					    <el-date-picker
					      v-model="addListForm.TERMDATE"
						  value-format="yyyy-MM-dd"
					      type="date"
					      placeholder="授权日期">
					    </el-date-picker>
					</el-form-item>
					<!-- <el-form-item label="状态" prop="ISCHECKED">
					  <el-switch
						v-model="addListForm.ISCHECKED"
						active-color="#13ce66"
						inactive-color="#ff4949">
					  </el-switch>
					</el-form-item> -->
					<el-checkbox-group v-model="checkList" @change="checkchange">
					    <el-checkbox label="库存权限"></el-checkbox>
					    <el-checkbox label="销售权限"></el-checkbox>
					    <el-checkbox label="会员权限"></el-checkbox>
					</el-checkbox-group>
					<el-checkbox-group v-model="addListForm.messCheck" @change="messchange" style="margin-top: 20px;">
					    <el-checkbox label="短信发送"></el-checkbox>
					</el-checkbox-group>
				  </el-form>
			  </div>
			  <div style="margin-top: 30px;">
				  <el-button type="primary" @click="editList">保 存</el-button>
				  <!-- <el-button type="danger" v-if="showtypes == 1" @click="delList">删 除</el-button> -->
			  </div>
		  </div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				GRADE:0,
				checkList: [],
				dates: '',
				drawer: false,			//抽屉	
				queryInfo:{
					query:'',
					pagenum:1,
					pagesize:10
				},
				ArrList: [],
				total:0,
				// 添加用户显示框
				addUserVisible:false,
				//编辑用户显示框
				editUserVisible:false,
				// 添加用户参数
				addListForm:{
					SID:'',
					SMOBILE:'',
					PASSWORD:'',
					CDATE:'',
					TERMDATE:'',
					ISCHECKED:'',
					DLID:'',
					orgId:'',
					orgMercode:'',
					orgTermno:'',
					UserRight:'',
					messCheck:false
				},
				// 添加用户对话框验证规则
				addListFormRul:{
					MATTCODE:[
						{ required: true, message: '请输入货号', trigger: 'blur' },
						{ min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
					],
					COLOUR:[
						{ required: true, message: '请输入颜色', trigger: 'blur' },
						{ min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
					],
					CUST:[
						{ required: true, message: '请输入客户', trigger: 'blur' },
						{ min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
					]
			
				}
				
				
			}
		},
		methods:{
			handleEdit(index, row) {
			        // console.log(index, row);
					this.addListForm.SID = row['SID']
					this.addListForm.DLID = row['DLID']
					this.addListForm.SMOBILE = row['SMOBILE']
					this.addListForm.PASSWORD = row['PASSWORD']
					this.addListForm.CDATE = row['CDATE']
					this.addListForm.TERMDATE = row['TERMDATE']
					this.drawer = true
					this.addListForm.ISCHECKED = row['ISCHECKED']
					this.addListForm.orgId = row['orgId']
					this.addListForm.orgMercode = row['orgMercode']
					this.addListForm.orgTermno = row['orgTermno']
					let arr = row['UserRight'].split(",");
					// console.log(arr); //字符串转数组
					this.checkList = arr
			},
			checkchange(){
				let str = this.checkList.join(',');
				this.addListForm.UserRight = str
				// console.log(this.addListForm.UserRight)
			},
			messchange(){
				// this.addListForm.UserRight = str
				console.log(this.addListForm.messCheck)
			},
			handleClose(done){
				// this.addListForm = {};
				this.drawer =false
			},
			handleAdd(index, row) {
				this.tableData.push({
					DDSIZE: 0,
					DDNUM: 0,
					LLNUM: 0,
					SHNUM:0,
					ISOK:false
				});
			},
			handleDelete(index, row) {
				if (index > 0) {
					this.tableData.splice(index, 1);
				}
			},
			tableRowClassName({ row, rowIndex }) {
				if ((rowIndex+1)%2 === 1) {
					return 'warning-row';
				} else{
					return 'success-row';
				}
				return '';
			},
			//获取选中行的数据
			handleCurrentChange(val) {
				this.showtypes = 1
				let data = val.DETAIL.substring(1);					//删除第一个”
				let datas = data.substring(0, data.length - 1);		//删除最后一个”
				datas = JSON.parse(datas);
				this.tableData = datas
				// console.log(this.tableData)
				this.addListForm.MATTCODE = val.MATTCODE
				this.addListForm.COLOUR = val.COLOUR
				this.addListForm.CUST = val.CUST
				this.addListForm.DATES = val.DATES
				this.addListForm.DEMO = val.DEMO
				this.addListForm.DATETIME = val.DATETIME
				this.drawer = true
				this.billid = val.BILLID
			  },
			//清空input
			seachVal(){
				this.ListArr()
			},
			//获取列表
			ListArr(){
				this.ArrList = []
				let parm = new URLSearchParams()
				let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
				// console.log(getUserSession.ID);
				parm.append('DLID',getUserSession.ID);
				parm.append('query',this.queryInfo.query);
				parm.append('TOKEN',getUserSession.token);
				// parm.append('pagenum',this.queryInfo.pagenum);
				// parm.append('pagesize',this.queryInfo.pagesize);
				this.$axios.post('/shoplist.php',parm)
				.then(res=>{
					// console.log(res)
					if (res.data.code == 200){
						this.ArrList = res.data.data
						// console.log(this.ArrList)
						this.total = res.data.total
					}
				})
			},
			// 每页数据条数发生改变触发
			SizeChange(newsize){
				this.queryInfo.pagesize = newsize;
				this.ListArr();
			},
			// 当前页码发生改变触发
			CurrentChange(newnum){
				this.queryInfo.pagenum = newnum;
				this.ListArr();
			},
			// 添加订单事件
			AddList(){
				// console.log(this.tableData)
				let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
				// console.log(getUserSession.token)
				let parm = new URLSearchParams()
				parm.append('token',getUserSession.token);
				parm.append('MATTCODE',this.addListForm.MATTCODE);
				parm.append('COLOUR',this.addListForm.COLOUR);
				parm.append('CUST',this.addListForm.CUST);
				parm.append('DATES',this.addListForm.DATES);
				parm.append('DEMO',this.addListForm.DEMO);
				parm.append('detail',JSON.stringify(this.tableData));
				this.$refs.addListForm.validate((valid) => {
					if (!valid) return;
					this.$axios.post('/?m=addlist',parm).then(res=>{
						// console.log(res)
						if(res.data.code == 200) {
							this.$message.success(res.data.msg)
							this.ListArr()
							this.drawer = false
						} else {
							this.$message.error(res.data.msg)
						}
						
					})
				})
			},
			// 保存编辑事件
			editList(){
				let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
				// console.log(this.addListForm)
				let parm = new URLSearchParams()
				parm.append('master',JSON.stringify(this.addListForm));
				parm.append('TOKEN',getUserSession.token);
				this.$refs.addListForm.validate((valid) => {
					if (!valid) return;
					this.$axios.post('/editshop.php',parm).then(res=>{
						// console.log(res)
						if(res.data.code == 200) {
							this.$message.success(res.data.msg)
							this.ListArr()
							this.drawer = false
						} else {
							this.$message.error(res.data.msg)
						}
						
					})
				})
			},
		},
		created() {
			this.ListArr()
			let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
			this.GRADE = getUserSession.GRADE;
		}
	}
</script>

<style scoped>
	.bigDiv{font-family: 微软雅黑,"microsoft yahei";}
	.drawer{padding: 20px;}
	.d_header{display: flex;align-items: center;}
	.d_header h1{font-weight: 100;font-size: 25px;font-family: 微软雅黑;float: left;padding: 0;margin: 0;}
	.d_header span {color: #ccc;}
</style>
